import React, { useEffect, useState } from "react";
import { SearchFiltersWrapper } from "./styled";
import { StyledCustomSelect } from "../../assets/styles/globalCommon";
import l from "lodash";

const SearchFilters = ({ items, sortOptions }) => {
	const [sortOption, setSortOption] = useState({});

	useEffect(() => {
		l.forEach(sortOptions, (option, index) => {
			if (index === 0) setSortOption(option);
		});
	}, []);

	const handleSortChange = (option) => {
		setSortOption(option);
	};

	return (
		<SearchFiltersWrapper>
			<div className="orderWrapper">
				<p>Ordenar por</p>
				<StyledCustomSelect
					classNamePrefix="custom-select"
					components={{
						IndicatorSeparator: () => null,
					}}
					options={sortOptions}
					onChange={handleSortChange}
					value={sortOption}
					isSearchable={false}
				/>
			</div>

			<div className="resultsWrapper">
				<p>{items.length} Resultados</p>
			</div>
		</SearchFiltersWrapper>
	);
};

export default SearchFilters;
