import styled from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";
import { StyledCustomSelect } from "../../assets/styles/globalCommon";

export const SearchFiltersWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${colors.black10};
	padding: 20px 25px;
	margin-bottom: 20px;
	@media ${mediaQueryMax.sm} {
		flex-direction: column;
		justify-content: center;
	}
	p {
		font-size: 15px;
	}
	.orderWrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@media ${mediaQueryMax.sm} {
			display: block;
			width: 100%;
			margin-bottom: 20px;
		}
		p {
			color: ${colors.gray82};
			margin-right: 30px;
			@media ${mediaQueryMax.md} {
				display: none;
			}
		}
		${StyledCustomSelect} {
			width: 220px;
			@media ${mediaQueryMax.sm} {
				width: 100%;
			}
		}
	}
	.resultsWrapper {
		p {
			color: ${colors.white};
		}
	}
`;
